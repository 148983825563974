<template>
  <Layout>
    <div class="content">
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="120px"
        label-position="left"
      >
        <h4 class="title">
          基本信息
        </h4>
        <el-form-item
          label="邀请活动名称"
          prop="activityDetail.activityName"
        >
          <el-input
            v-model="formData.activityDetail.activityName"
            placeholder="单行输入"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="渠道类型"
          prop="activityDetail.channelId"
        >
          <el-select
            v-model="formData.activityDetail.channelId"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionChannelList"
              :key="index"
              :label="item.channelName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="渠道名称"
          prop="activityDetail.channelName"
        >
          <el-input
            v-model="formData.activityDetail.channelName"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="邀请码通用段"
          prop="activityDetail.generateMode"
        >
          <el-radio-group
            v-model="formData.activityDetail.generateMode"
            :disabled="isInvitationCodeEdit"
          >
            <el-radio
              :label="1"
              class="radio"
            >
              <span>系统生成</span>
            </el-radio>
            <el-radio
              :label="2"
              class="radio"
            >
              <span>手动填写</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="formData.activityDetail.generateMode === 2"
          prop="activityDetail.inviteCode"
        >
          <el-input
            v-model="formData.activityDetail.inviteCode"
            :disabled="isInvitationCodeEdit"
            placeholder="手动填写邀请码"
          />
        </el-form-item>
        <el-form-item
          label="邀请码类型"
          prop="activityDetail.codeType"
        >
          <el-radio-group
            v-model="formData.activityDetail.codeType"
            :disabled="isInvitationCodeEdit"
          >
            <el-radio
              :label="1"
              class="radio"
            >
              <span>通用码</span>
            </el-radio>
            <el-radio
              :label="2"
              class="radio"
            >
              <span style="margin-right: 20px;">1人1码</span>
              <el-input-number
                v-if="formData.activityDetail.codeType === 2"
                v-model="formData.activityDetail.codeNum"
                :disabled="isInvitationCodeEdit"
                placeholder="生成数量"
                :step="1"
                step-strictly
                :min="10"
                :max="100000"
              />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="可用时间"
          prop="dateRange"
        >
          <el-date-picker
            v-model="formData.dateRange"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <h4 class="title">
          奖品配置
        </h4>
        <prizeItem
          v-for="(item, index) in formData.prizeDetail"
          ref="subForm"
          :key="item.id"
          :index="index"
          :form="item"
          :reward-count-list="rewardCountList"
          :trigger-mode-list="triggerModeList"
          :all-aeward-count-list-map="allAewardCountListMap"
          :all-reward-count-map="allRewardCountMap"
          @remove="handleRemove"
        />
        <el-button
          :disabled="formData.prizeDetail.length > 1"
          @click="handleAddReward"
        >
          新增奖品
        </el-button>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  promotionStateList,
  promotionStateMap,
  triggerModeList,
  triggerModeMap,
  rewardTypetMap,
  channelList,
  appList, appointDiscountRewardTypeList
} from '@/utils/selectOptions.js'
import { cloneDeep } from 'lodash'
/* eslint-disable */
import {
  getAllChannelType,
  createInviteActivity,
  getInviteActivityDetail,
  updateInviteActivity,
  couponCodeGetGoods, GetZoneGoods
} from "@/api/CouponsAndRedeemCode.js";

import prizeItem from "./prizeItem";
import {
  APPID,
  GOODS_TYPE,
  CHANNEL,
  EXPERIMENT_GROUP,
  triggerModeIndex,
  triggerModeValue
} from "@/enum";

export default {
  name: "CreateOrEditInvitationCode",
  components: { prizeItem },
  data() {
    const dateRangeValidator = (rule, value, callback) => {
      if (!value || !value.length || !value[0]) {
        callback(new Error("时间范围必选"));
      } else {
        callback();
      }
    };
    const codeNumValidator = (rule, value, callback) => {
      if (
        this.formData.activityDetail.codeType === 2 &&
        !this.formData.activityDetail.codeNum
      ) {
        callback(new Error("1人1码数量必填"));
      } else {
        callback();
      }
    };
    const inviteCodeValidator = (rule, value, callback) => {
      const Regx = /^[A-Za-z0-9]*$/;
      if (
        this.formData.activityDetail.generateMode === 2 &&
        this.formData.activityDetail.codeType === 2
      ) {
        if (String(value).length !== 5) {
          callback(new Error("1人1码的通用段长度需等于5"));
        } else if (!Regx.test(value)) {
          callback(new Error("1人1码的通用段必须是数字或者字母"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      promotionStateList,
      promotionStateMap,
      promotionChannelList: [],
      triggerModeMap,
      rewardCountList: [],
      rewardCountMap: {},
      rewardTypetMap,
      appointDiscountRewardTypeList,
      allAewardCountListMap: {},
      allRewardCountMap: {},
      isInvitationCodeEdit: false,
      fetchLoading: false,
      formData: {
        activityDetail: {},
        prizeDetail: []
      },
      rules: {
        "activityDetail.activityName": [
          { required: true, message: "邀请活动名称必填" }
        ],
        "activityDetail.channelId": [
          { required: true, message: "渠道类型必填" }
        ],
        "activityDetail.channelName": [{ required: true, message: "渠道名称" }],
        dateRange: [{ required: true, validator: dateRangeValidator }],
        "activityDetail.generateMode": [
          { required: true, message: "邀请码生成方式必填" }
        ],
        "activityDetail.inviteCode": [
          { required: true, message: "邀请码必填" },
          { validator: inviteCodeValidator }
        ],
        "activityDetail.codeType": [
          { required: true, message: "邀请码类型必填" },
          { validator: codeNumValidator }
        ]
      },
      updateLoading: false,
    };
  },
  computed: {
    hasRegisterCoin() {
      return this.formData.prizeDetail.some(
        item => item.triggerMode === triggerModeValue.REGISTER_COIN
      );
    },
    hasFirstChargeCoin() {
      return this.formData.prizeDetail.some(
        item => item.triggerMode === triggerModeValue.FIRST_CHARGE_COIN
      );
    },
    hasFirstChargeDiscount() {
      return this.formData.prizeDetail.some(
        item => item.triggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT
      );
    },
    triggerModeList() {
      const result = cloneDeep(triggerModeList);
      result[triggerModeIndex.REGISTER_COIN].disabled = !!this.hasRegisterCoin;
      if (this.hasFirstChargeCoin || this.hasFirstChargeDiscount) {
        result[triggerModeIndex.FIRST_CHARGE_COIN].disabled = true;
        result[triggerModeIndex.FIRST_CHARGE_DISCOUNT].disabled = true;
      } else {
        result[triggerModeIndex.FIRST_CHARGE_COIN].disabled = false;
        result[triggerModeIndex.FIRST_CHARGE_DISCOUNT].disabled = false;
      }
      return result;
    }
  },
  created() {
    this.isInvitationCodeEdit = this.$route.name === "InvitationCodeEdit";
    if (this.isInvitationCodeEdit) {
      this.handleQueryFormInfo();
    }
    // this.queryRewardCountList();
    this.queryAllRewardCountList();
    this.getAllChannelType();
  },
  methods: {
    queryRewardCountList() {
      couponCodeGetGoods({
        appId: APPID.Netboom,
        channel: CHANNEL.Android,
        experimentGroup: EXPERIMENT_GROUP.BASE_GROUP,
        goodsType: GOODS_TYPE.GOLD
      }).then(res => {
        if (res.code === 200) {
          const list = res.data.list || [];
          const tempList = [];
          list.forEach((item, index) => {
            tempList[index] = {
              name: item.name,
              value: item.goodsId
            };
          });
          this.$set(this, "rewardCountList", tempList);
          const rewardCountMap = list.reduce((result, item) => {
            result[item.goodsId] = item;
            return result;
          }, {});
          this.$set(this, "rewardCountMap", rewardCountMap);
        }
      });
    },
    queryAllRewardCountList() {
      const ENUM_PARAMS = [];
      const ENUM_INDEX = [];
      channelList.forEach(item => {
        appList.forEach(it => {
          ENUM_PARAMS.push({
            appId: it.value,
            channel: item.value
          });
          ENUM_INDEX.push(`${item.name.toUpperCase()}_${it.value}`);
        });
      });
      // Promise.all(
      //   ENUM_PARAMS.map(item => {
      //     return couponCodeGetGoods({
      //       ...item,
      //       experimentGroup: EXPERIMENT_GROUP.BASE_GROUP,
      //       goodsType: GOODS_TYPE.bothVipAndGold
      //     });
      //   })
      // ).then(resList => {
      //   const tempMap = {};
      //   const allRewardCountMap = {};
      //   resList.forEach((item, index) => {
      //     tempMap[ENUM_INDEX[index]] = (item.data.list || []).map(it => {
      //       allRewardCountMap[it.goodsId] = it;
      //       return {
      //         name: it.name,
      //         type: it.type,
      //         value: it.goodsId
      //       };
      //     });
      //   });
      //   this.$set(this, "allAewardCountListMap", tempMap);
      //   this.$set(this, "allRewardCountMap", allRewardCountMap);
      // });
      Promise.all(
        ENUM_PARAMS.map(item => {
          return GetZoneGoods({
            zoneId:0,
            channel:5
          });
        })
      ).then(resList => {
        const tempMap = {};
        const allRewardCountMap = {};
        resList.forEach((item, index) => {
          tempMap[ENUM_INDEX[index]] = (item.data.list || []).map(it => {
            allRewardCountMap[it.goodsId] = it;
            return {
              name: it.name,
              priceDesc: it.priceDesc,
              value: it.goodsId
            };
          });
        });
        this.$set(this, "allAewardCountListMap", tempMap);
        this.$set(this, "allRewardCountMap", allRewardCountMap);
      });
    },
    getAllChannelType() {
      getAllChannelType({ activityType: 2 }).then(res => {
        if (res.code === 200) {
          this.promotionChannelList = res.data.list || [];
        }
      });
    },
    handleQueryFormInfo() {
      const id = Number(this.$route.query.id);
      this.fetchLoading = true;
      getInviteActivityDetail({ id })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data;
            const { startUsedTime, endUsedTime } = this.formData.activityDetail;
            this.$set(this.formData, "dateRange", [startUsedTime, endUsedTime]);
            // 此接口逻辑混乱不堪, 因此重新映射字段以简化前端逻辑, 上传前再映射回来
            console.log('triggerModeValue',triggerModeValue)

            console.log(this.formData)
            let invitePrizeGoods = []
            let prizeDetail = {}
            let TriggerMode = 0

            if (this.formData.prizeDetail == null){
              this.formData.prizeDetail = []
            }

            this.formData.prizeDetail.forEach(item => {
              item.payType = item.payType
              item.prizeNum = item.prizeNum / 100
              invitePrizeGoods.push(item)
              TriggerMode = item.triggerMode
              prizeDetail.prizeName = item.prizeName
              prizeDetail.prizeType = item.prizeType
              prizeDetail.triggerMode = item.triggerMode
            })

            if (TriggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT) {
              prizeDetail.invitePrizeGoods = invitePrizeGoods
              this.formData.prizeDetail = []
              this.formData.prizeDetail.push(prizeDetail)

              console.log(this.formData.prizeDetail)

            }else{
              this.formData.prizeDetail = (this.formData.prizeDetail || []).map(
                item => ({
                  id: Math.random()
                    .toString(32)
                    .slice(2),
                  triggerMode: item.triggerMode,
                  register_coin_prizetype:
                    item.triggerMode === triggerModeValue.REGISTER_COIN
                      ? item.prizeType
                      : "",
                  register_coin_goodsId:
                    item.triggerMode === triggerModeValue.REGISTER_COIN
                      ? item.goodsId
                      : "",
                  register_coin_prizenum:
                    item.triggerMode === triggerModeValue.REGISTER_COIN
                      ? item.prizeNum
                      : "",

                  first_charge_coin_list:
                    item.triggerMode === triggerModeValue.FIRST_CHARGE_COIN
                      ? item.giftPrize
                      : [{}],

                  first_charge_discount_prizetype:
                    item.triggerMode === triggerModeValue.FIRST_CHARGE_COIN
                      ? item.prizeType
                      : "",
                  first_charge_discount_prizenum:
                    item.triggerMode === triggerModeValue.FIRST_CHARGE_COIN
                      ? item.prizeNum
                      : ""
                })
              );

            }
          }
        })
        .finally(() => {
          this.fetchLoading = false;
        });

      console.log(this.formData)

    },
    handleAddReward() {
      this.formData.prizeDetail.push({
        id: Math.random()
          .toString(32)
          .slice(2),
        triggerMode: "",

        register_coin_prizetype: "",
        register_coin_goodsId: "",
        register_coin_prizenum: "",

        first_charge_coin_list: [{}],
        first_pay_discount_list: [{}],
        invitePrizeGoods:[{}],

        first_pay_discount_prizetype: "",
        first_charge_discount_prizetype: "",
        first_charge_discount_prizenum: ""
      });
    },
    handleRemove(index) {
      this.formData.prizeDetail.splice(index, 1);
    },
    handleCheckAllForm() {
      return new Promise(resolve => {
        console.log("11")
        Promise.all([
          this.$refs.form.validate(),
          ...(this.$refs.subForm || []).map(item => item.validate())
        ])
          .then(valid => {
            if (valid) {
              resolve();
            }
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    handleSubmit() {
      if (this.isInvitationCodeEdit) {
        this.updateInvitationActivity()
      } else {
        this.createInvitationActivity()
      }
    },
    createInvitationActivity() {
      this.handleCheckAllForm().then(() => {
        this.updateLoading = true;
        const formData = this.preFormatFormData();
        createInviteActivity({ ...formData })
          .then(res => {
            if (res.code === 200) {
              this.$message.success("新建成功");
              this.$router.back();
            }
          })
          .finally(() => {
            this.updateLoading = false;
          });
      });
    },
    updateInvitationActivity() {

      console.log("up")

      this.handleCheckAllForm().then(() => {
        this.updateLoading = true;
        const formData = this.preFormatFormData();

        console.log(formData)

        updateInviteActivity({ ...formData })
          .then(res => {
            if (res.code === 200) {
              this.$message.success("更新成功");
              this.$router.back();
            }
          })
          .finally(() => {
            this.updateLoading = false;
          });
      });
    },
    preFormatFormData() {
      const formData = cloneDeep(this.formData);
      formData.activityDetail.startUsedTime = formData.dateRange[0];
      formData.activityDetail.endUsedTime = formData.dateRange[1];
      delete formData.dateRange;

      if (formData.activityDetail.generateMode === 1) {
        formData.activityDetail.inviteCode = "";
      }
      if (formData.activityDetail.codeType === 1) {
        formData.activityDetail.codeNum = "";
      }


      console.log(formData.prizeDetail)

      formData.prizeDetail = formData.prizeDetail.map(item => {
        /* eslint-disable camelcase */
        const {
          triggerMode,
          register_coin_prizetype,
          register_coin_goodsId,
          register_coin_prizenum,
          invitePrizeGoods,
          prizeType,
          first_charge_discount_prizetype,
          first_charge_discount_prizenum
        } = item;
        let result = {};
        if (triggerMode === triggerModeValue.REGISTER_COIN) {
          result = {
            triggerMode,
            prizeName:
              (this.rewardTypetMap[register_coin_prizetype] || {}).name ||
              "未知",
            prizeNum:
              register_coin_prizetype === 1
                ? (this.rewardCountMap[register_coin_goodsId] || {}).gold
                : register_coin_prizenum,
            prizeType: register_coin_prizetype,
            goodsId: register_coin_prizetype === 2 ? "" : register_coin_goodsId
          };
        }

        if (triggerMode === triggerModeValue.FIRST_CHARGE_COIN) {
          result = {
            triggerMode,
            prizeName:
              (this.rewardTypetMap[first_charge_discount_prizetype] || {})
                .name || "未知",
            prizeType: first_charge_discount_prizetype,
            prizeNum: first_charge_discount_prizenum
          };
        }

        if (triggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT) {
          result = {
            triggerMode,
            prizeName:
              (this.rewardTypetMap[prizeType] || {})
                .name || "未知",
            prizeType: prizeType,
            invitePrizeGoods: invitePrizeGoods
          }
        }

        return result;
      });

      return formData;
    },
    handleCancel() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="less">
.content {
  margin: 50px 80px;
  min-width: 880px;
  h4.title {
    margin-bottom: 40px;

    &.title-2 {
      margin-top: 60px;
    }
  }

  .el-input,
  .el-textarea {
    width: 350px;
  }
  .ctrl-area {
    margin-top: 40px;
    .el-button {
      padding: 10px 40px;
    }
  }
}
</style>
