var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prizeDetail"},[_c('div',{staticClass:"rewardName"},[_vm._v(" 奖品 "+_vm._s(_vm.index + 1)+" ")]),_c('div',{staticClass:"form"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"size":"small","label-width":"100px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":"触发类型","prop":"triggerMode","rules":[
          { required: true, message: '触发类型必选' }
        ]}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.form.triggerMode),callback:function ($$v) {_vm.$set(_vm.form, "triggerMode", $$v)},expression:"form.triggerMode"}},_vm._l((_vm.triggerModeListFocus),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value,"disabled":it.disabled}})}),1)],1),(_vm.form.triggerMode === _vm.triggerModeValue.REGISTER_COIN)?_c('el-form-item',{attrs:{"label":"奖品内容","prop":"register_coin_prizetype","rules":[
          { required: true, message: '奖品内容必选' },
          { validator: _vm.validatorCheckPrizeRequired}
        ]}},[_c('el-select',{staticStyle:{"margin-right":"20px"},attrs:{"clearable":""},model:{value:(_vm.form.register_coin_prizetype),callback:function ($$v) {_vm.$set(_vm.form, "register_coin_prizetype", $$v)},expression:"form.register_coin_prizetype"}},_vm._l((_vm.rewardTypeList),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value}})}),1),(_vm.form.register_coin_prizetype === 1)?_c('el-select',{model:{value:(_vm.form.register_coin_goodsId),callback:function ($$v) {_vm.$set(_vm.form, "register_coin_goodsId", $$v)},expression:"form.register_coin_goodsId"}},_vm._l((_vm.rewardCountList),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value}})}),1):(_vm.form.register_coin_prizetype === 2)?_c('el-input-number',{staticStyle:{"width":"192px"},attrs:{"min":0,"placeholder":"请填写金币数量"},model:{value:(_vm.form.register_coin_prizenum),callback:function ($$v) {_vm.$set(_vm.form, "register_coin_prizenum", $$v)},expression:"form.register_coin_prizenum"}}):_vm._e()],1):_vm._e(),(_vm.form.triggerMode === _vm.triggerModeValue.FIRST_CHARGE_COIN)?_c('el-form-item',{attrs:{"label":"奖品内容","prop":"first_charge_discount_prizetype","rules":[
          { required: true, message: '奖品内容必选' },
          { validator: _vm.validatorCheckDiscountRequired }
        ]}},[_c('el-select',{staticStyle:{"margin-right":"20px"},attrs:{"clearable":""},model:{value:(_vm.form.first_charge_discount_prizetype),callback:function ($$v) {_vm.$set(_vm.form, "first_charge_discount_prizetype", $$v)},expression:"form.first_charge_discount_prizetype"}},_vm._l((_vm.discountRewardTypeList),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value}})}),1),_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入金币","maxlength":"20"},model:{value:(_vm.form.first_charge_discount_prizenum),callback:function ($$v) {_vm.$set(_vm.form, "first_charge_discount_prizenum", $$v)},expression:"form.first_charge_discount_prizenum"}})],1):_vm._e(),(_vm.form.triggerMode === _vm.triggerModeValue.FIRST_CHARGE_DISCOUNT)?_c('el-form-item',{attrs:{"label":"奖品类型","prop":"prizeType","rules":[
          { required: true, message: '触发类型必选' }
        ]}},[_c('el-select',{staticStyle:{"margin-right":"20px"},attrs:{"clearable":""},model:{value:(_vm.form.prizeType),callback:function ($$v) {_vm.$set(_vm.form, "prizeType", $$v)},expression:"form.prizeType"}},_vm._l((_vm.appointDiscountRewardTypeList),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value}})}),1)],1):_vm._e(),(_vm.form.triggerMode === _vm.triggerModeValue.FIRST_CHARGE_DISCOUNT)?_c('el-form-item',{attrs:{"label":"奖品内容","prop":"invitePrizeGoods","rules":[
          { required: true, message: '触发类型必选' }
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("奖品内容 ")])]},proxy:true}],null,false,4170893867)},[_c('el-table',{attrs:{"border":"","data":_vm.form.invitePrizeGoods},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"table-footer"},[_c('el-button',{attrs:{"type":"pramry","plain":""},on:{"click":_vm.handleAddGoodsItem}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 新增首充赠金币商品 ")])],1)]},proxy:true}],null,false,1297814925)},[_c('el-table-column',{attrs:{"label":"支付方式","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({row, $index}){return [_c('el-form-item',{attrs:{"prop":`invitePrizeGoods.${$index}.payType`,"rules":[
                  {required: true, message: '支付方式必选'}
                ]}},[_c('el-select',{attrs:{"clearable":""},model:{value:(row.payType),callback:function ($$v) {_vm.$set(row, "payType", $$v)},expression:"row.payType"}},_vm._l((_vm.payList),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name,"value":it.value}})}),1)],1)]}}],null,false,621657146)}),_c('el-table-column',{attrs:{"label":"目标商品","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function({row, $index}){return [_c('el-form-item',{attrs:{"prop":`invitePrizeGoods.${$index}.goodsId`,"rules":[
                  {required: true, message: '目标商品必选'}
                ]}},[_c('el-select',{attrs:{"clearable":""},model:{value:(row.goodsId),callback:function ($$v) {_vm.$set(row, "goodsId", $$v)},expression:"row.goodsId"}},_vm._l((_vm.GetZoneGoodsList(row)),function(it,ind){return _c('el-option',{key:ind,attrs:{"label":it.name + ' 价格：' + it.priceDesc + '元',"value":it.goodsId}})}),1)],1)]}}],null,false,533450254)}),_c('el-table-column',{attrs:{"label":"折扣后的价格","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function({row, $index}){return [_c('el-form-item',{attrs:{"prop":`invitePrizeGoods.${$index}.newPrice`}},[_c('el-input',{staticStyle:{"width":"130px"},model:{value:(row.newPrice),callback:function ($$v) {_vm.$set(row, "newPrice", $$v)},expression:"row.newPrice"}}),_vm._v(" 元 ")],1)]}}],null,false,3599838855)}),_c('el-table-column',{attrs:{"label":"折扣","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function({row, $index}){return [_c('el-form-item',{attrs:{"prop":`invitePrizeGoods.${$index}.prizeNum`}},[_c('el-input',{staticStyle:{"width":"90px"},model:{value:(row.prizeNum),callback:function ($$v) {_vm.$set(row, "prizeNum", $$v)},expression:"row.prizeNum"}}),_vm._v(" 折 ")],1)]}}],null,false,2503782919)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function({$index}){return [(_vm.form.invitePrizeGoods.length > 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRemoveGoodsItem($index)}}},[_c('i',{staticClass:"el-icon-close closeIcon"})]):_vm._e()]}}],null,false,663787614)})],1)],1):_vm._e()],1)],1),_c('el-button',{staticClass:"close",attrs:{"type":"text","title":"移除该奖品"},on:{"click":function($event){return _vm.handleRemove(_vm.form, _vm.index)}}},[_c('i',{staticClass:"el-icon-close"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }