<template>
  <div class="prizeDetail">
    <div class="rewardName">
      奖品 {{ index + 1 }}
    </div>
    <div class="form">
      <el-form
        ref="form"
        :model="form"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <!-- 触发类型 -->
        <el-form-item
          label="触发类型"
          prop="triggerMode"
          :rules="[
            { required: true, message: '触发类型必选' }
          ]"
        >
          <el-select
            v-model="form.triggerMode"
            clearable
          >
            <el-option
              v-for="(it, ind) in triggerModeListFocus"
              :key="ind"
              :label="it.name"
              :value="it.value"
              :disabled="it.disabled"
            />
          </el-select>
        </el-form-item>

        <!-- 触发类型 注册金币-->
        <el-form-item
          v-if="form.triggerMode === triggerModeValue.REGISTER_COIN"
          label="奖品内容"
          prop="register_coin_prizetype"
          :rules="[
            { required: true, message: '奖品内容必选' },
            { validator: validatorCheckPrizeRequired}
          ]"
        >
          <!-- 奖品内容 -->
          <el-select
            v-model="form.register_coin_prizetype"
            clearable
            style="margin-right: 20px;"
          >
            <el-option
              v-for="(it, ind) in rewardTypeList"
              :key="ind"
              :label="it.name"
              :value="it.value"
            />
          </el-select>
          <!--  线上金币数量选择-->
          <el-select
            v-if="form.register_coin_prizetype === 1"
            v-model="form.register_coin_goodsId"
          >
            <el-option
              v-for="(it, ind) in rewardCountList"
              :key="ind"
              :label="it.name"
              :value="it.value"
            />
          </el-select>
          <!--  自定义金币数量-->
          <el-input-number
            v-else-if="form.register_coin_prizetype === 2"
            v-model="form.register_coin_prizenum"
            style="width: 192px;"
            :min="0"
            placeholder="请填写金币数量"
          />
        </el-form-item>

        <!-- 触发类型 首充金币-->
        <el-form-item
          v-if="form.triggerMode === triggerModeValue.FIRST_CHARGE_COIN"
          label="奖品内容"
          prop="first_charge_discount_prizetype"
          :rules="[
            { required: true, message: '奖品内容必选' },
            { validator: validatorCheckDiscountRequired }
          ]"
        >
          <el-select
            v-model="form.first_charge_discount_prizetype"
            clearable
            style="margin-right: 20px;"
          >
            <el-option
              v-for="(it, ind) in discountRewardTypeList"
              :key="ind"
              :label="it.name"
              :value="it.value"
            />
          </el-select>

          <el-input
            v-model="form.first_charge_discount_prizenum"
            style="width:200px ;"
            placeholder="请输入金币"
            maxlength="20"
          />
          <!-- <el-select
            v-model="form.first_charge_discount_prizenum"
          >
            <el-option
              v-for="(it, ind) in discountRewardCountList"
              :key="ind"
              :label="it.name"
              :value="it.value"
            />
          </el-select> -->
          <!-- <div style="color: red;">
            目前iOS仅支持全部商品，请谨慎操作！
          </div> -->
        </el-form-item>
        <!-- <el-form-item
          v-if="form.triggerMode === triggerModeValue.FIRST_CHARGE_COIN"
          label="奖品内容"
        >
          <template #label>
            <span class="custom-label">奖品内容</span>
          </template>
          <el-table
            border
            :data="form.first_charge_coin_list"
          >
            <el-table-column
              label="平台"
              width="120px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`first_charge_coin_list.${$index}.platform`"
                  :rules="[
                    {required: true, message: '平台必选'}
                  ]"
                >
                  <el-select
                    v-model="row.platform"
                    clearable
                    @change="resetGoodsId(row)"
                  >
                    <el-option
                      v-for="(it, ind) in osPlatformList"
                      :key="ind"
                      :label="it.name"
                      :value="it.value"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="APP"
              width="130px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`first_charge_coin_list.${$index}.appId`"
                  :rules="[
                    {required: true, message: 'App必选'}
                  ]"
                >
                  <el-select
                    v-model="row.appId"
                    clearable
                    @change="resetGoodsId(row)"
                  >
                    <el-option
                      v-for="(it, ind) in appList"
                      :key="ind"
                      :label="it.name"
                      :value="it.value"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="目标商品"
              width="150px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`first_charge_coin_list.${$index}.goodsId`"
                  :rules="[
                    {required: true, message: '目标商品必选'}
                  ]"
                >
                  <el-select
                    v-model="row.goodsId"
                    clearable
                  >
                    <el-option
                      v-for="(it, ind) in computedAllAewardCountList(row)"
                      :key="ind"
                      :label="it.name"
                      :value="it.value"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="附赠时长/金币数量"
              width="200px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`first_charge_coin_list.${$index}.prizeNum`"
                  :rules="[
                    {required: true, message: '附赠时长/金币数量必填'}
                  ]"
                >
                  <el-input-number
                    v-model="row.prizeNum"
                    :min="0"
                    style="width: 170px"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="赠送次数/按月"
              width="180px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`first_charge_coin_list.${$index}.prizeCount`"
                  :rules="[
                    {required: true, message: '赠送次数/按月必填'},
                    {validator: validatorCheckPrizeCount}
                  ]"
                >
                  <el-input-number
                    v-model="row.prizeCount"
                    :min="0"
                    :max="(allRewardCountMap[row.goodsId] || {}).type === 2 ? Infinity : 1"
                    style="width: 130px"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column>
              <template #default="{$index}">
                <el-button
                  v-if="form.first_charge_coin_list.length > 1"
                  type="text"
                  @click="handleRemoveGoodsItem($index)"
                >
                  <i class="el-icon-close closeIcon" />
                </el-button>
              </template>
            </el-table-column>
            <template #append>
              <div class="table-footer">
                <el-button
                  type="pramry"
                  plain
                  @click="handleAddGoodsItem"
                >
                  <i class="el-icon-plus" />
                  新增首充赠金币商品
                </el-button>
              </div>
            </template>
          </el-table>
        </el-form-item> -->

        <!-- 触发类型 首充折扣-->
        <el-form-item
          v-if="form.triggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT"
          label="奖品类型"
          prop="prizeType"
          :rules="[
            { required: true, message: '触发类型必选' }
          ]"
        >
          <el-select
            v-model="form.prizeType"
            clearable
            style="margin-right: 20px;"
          >
            <el-option
              v-for="(it, ind) in appointDiscountRewardTypeList"
              :key="ind"
              :label="it.name"
              :value="it.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.triggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT"
          label="奖品内容"
          prop="invitePrizeGoods"
          :rules="[
            { required: true, message: '触发类型必选' }
          ]"
        >
          <template #label>
            <span>奖品内容 </span>
          </template>
          <el-table
            border
            :data="form.invitePrizeGoods"
          >
            <el-table-column
              label="支付方式"
              width="120px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`invitePrizeGoods.${$index}.payType`"
                  :rules="[
                    {required: true, message: '支付方式必选'}
                  ]"
                >
                  <el-select
                    v-model="row.payType"
                    clearable
                  >
                    <el-option
                      v-for="(it, ind) in payList"
                      :key="ind"
                      :label="it.name"
                      :value="it.value"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="目标商品"
              width="200px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`invitePrizeGoods.${$index}.goodsId`"
                  :rules="[
                    {required: true, message: '目标商品必选'}
                  ]"
                >
                  <el-select
                    v-model="row.goodsId"
                    clearable
                  >
                    <el-option
                      v-for="(it, ind) in GetZoneGoodsList(row)"
                      :key="ind"
                      :label="it.name + ' 价格：' + it.priceDesc + '元'"
                      :value="it.goodsId"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="折扣后的价格"
              width="180px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`invitePrizeGoods.${$index}.newPrice`"
                >
                  <el-input
                    v-model="row.newPrice"
                    style="width: 130px"
                  /> 元
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              label="折扣"
              width="140px"
            >
              <template #default="{row, $index}">
                <el-form-item
                  :prop="`invitePrizeGoods.${$index}.prizeNum`"
                >
                  <el-input
                    v-model="row.prizeNum"
                    style="width: 90px"
                  /> 折
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="{$index}">
                <el-button
                  v-if="form.invitePrizeGoods.length > 1"
                  type="text"
                  @click="handleRemoveGoodsItem($index)"
                >
                  <i class="el-icon-close closeIcon" />
                </el-button>
              </template>
            </el-table-column>
            <template #append>
              <div class="table-footer">
                <el-button
                  type="pramry"
                  plain
                  @click="handleAddGoodsItem"
                >
                  <i class="el-icon-plus" />
                  新增首充赠金币商品
                </el-button>
              </div>
            </template>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <el-button
      type="text"
      class="close"
      title="移除该奖品"
      @click="handleRemove(form, index)"
    >
      <i class="el-icon-close" />
    </el-button>
  </div>
</template>

<script>
import {
  triggerModeIndex,
  triggerModeValue
} from '@/enum'
import { cloneDeep } from 'lodash'

import {
  rewardTypeList,
  discountRewardTypeList,
  appointDiscountRewardTypeList,
  discountRewardCountList,
  osPlatformList,
  appList
} from '@/utils/selectOptions.js'
export default {
  name: 'PrizeItem',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    rewardCountList: {
      type: Array,
      default: () => []
    },
    triggerModeList: {
      type: Array,
      default: () => []
    },
    allAewardCountListMap: {
      type: Object,
      default: () => {}
    },
    allRewardCountMap: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      prizetypeRewardTypeList: [],
      triggerModeValue,
      rewardTypeList,
      discountRewardTypeList,
      appointDiscountRewardTypeList,
      discountRewardCountList,
      osPlatformList,
      appList,
      rules: {
        triggerMode: [{ required: true, message: '触发类型必选' }]
      },
      payList: [{ value: 5, name: '支付宝' }, { value: 8, name: '微信' }],
      goodsList: []
      // form: {
      //   first_pay_discount_list: []
      // }
    }
  },
  computed: {
    isFirstChargeCoin () {
      return this.form.triggerMode === triggerModeValue.FIRST_CHARGE_COIN
    },
    isFirstChargeDiscount () {
      return this.form.triggerMode === triggerModeValue.FIRST_CHARGE_DISCOUNT
    },
    triggerModeListFocus () {
      const result = cloneDeep(this.triggerModeList)
      if (this.isFirstChargeCoin || this.isFirstChargeDiscount) {
        result[triggerModeIndex.FIRST_CHARGE_COIN].disabled = false
        result[triggerModeIndex.FIRST_CHARGE_DISCOUNT].disabled = false
      }
      // result.pop()
      // console.log(result)
      return result
    }
  },
  mounted () {
    console.log('dsa', this.form)
    console.log(this.allRewardCountMap)
  },
  methods: {
    GetZoneGoodsList (row) {
      console.log(this.allRewardCountMap)
      return this.allRewardCountMap
    },
    validatorCheckPrizeRequired  (rule, value, callback) {
      if (this.form.register_coin_prizetype === 1 && !this.form.register_coin_goodsId) {
        callback(new Error('请选择奖品内容'))
      } else if (this.form.register_coin_prizetype === 2 && (this.form.register_coin_prizenum !== 0 && !this.form.register_coin_prizenum)) {
        callback(new Error('请填写金币数量'))
      } else {
        callback()
      }
    },
    validatorCheckDiscountRequired (rule, value, callback) {
      if (!this.form.first_charge_discount_prizenum) {
        // callback(new Error('请选择折扣'))
      } else {
        callback()
      }
    },

    computedAllAewardCountList (row) {
      // const { appId, platform } = row
      // return this.allAewardCountListMap[`${platform}_${appId}`] || []
      return this.allRewardCountMap
    },
    handleAddGoodsItem () {
      if (!this.form.invitePrizeGoods) this.form.invitePrizeGoods = []
      this.form.invitePrizeGoods.push({ payType: 5 })
    },
    handleRemoveGoodsItem (index) {
      this.form.invitePrizeGoods.splice(index, 1)
    },
    handleRemove (item, index) {
      this.$emit('remove', index)
    },
    // 用于父级组件校验本组件
    async validate () {
      return this.$refs.form.validate().then(valid => {
        if (valid) {
          return Promise.resolve()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.prizeDetail {
  display: flex;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 20px;
  position: relative;
  .custom-label{
      &:before{
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
      }
    }
  .rewardName{
    width: 100px;
    flex-shrink: 0;
    line-height: 36px;
  }
  .close{
    position: absolute;
    right: 0;
    top: -24px;
    font-size: 20px;
  }
  .closeIcon {
    font-size: 16px;
  }
  .table-footer {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
}
table .el-form-item--small.el-form-item{
  margin-bottom: 0;
}
</style>
